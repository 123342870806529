import {FC} from 'react'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>P&aacute;gina no encontrada</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        La p&aacute;gina que busca no se encuentra! <br />
      </div>
    </>
  )
}

export {Error404}
