import React from 'react'

interface Props {
    totalScortActive: number;
    totalScortInactive: number;
}

export const EscortStats: React.FC<Props> = ({totalScortActive, totalScortInactive}) => {
  const total = totalScortActive + totalScortInactive

  return (
    <>
            <div className="card card-flush h-lg-100">
                <div className="card-header mt-6">
                    <div className="card-title flex-column">
                        <h3 className="fw-bolder mb-1">Mascotas Registrados</h3>
                        <div className="fs-6 fw-bold text-gray-400"></div>
                    </div>
                    {/* <div className="card-toolbar">
                        <a href="#" className="btn btn-light btn-sm">View Tasks</a>
                    </div> */}
                </div>
                <div className="card-body p-9 pt-5">
                    <div className="d-flex flex-wrap">
                        <div className="position-relative d-flex flex-center h-175px w-175px me-15 mb-7">
                            <div className="position-absolute translate-middle start-50 top-50 d-flex flex-column flex-center">
                                <span className="fs-2qx fw-bolder">{total}</span>
                                <span className="fs-6 fw-bold text-gray-400">TOTAL</span>
                            </div>
                            <canvas id="project_overview_chart"></canvas>
                        </div>
                        <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
                            {/* <div className="d-flex fs-6 fw-bold align-items-center mb-3">
                                <div className="bullet bg-primary me-3"></div>
                                <div className="text-gray-400">Active</div>
                                <div className="ms-auto fw-bolder text-gray-700">30</div>
                            </div> */}
                            <div className="d-flex fs-6 fw-bold align-items-center mb-3">
                                <div className="bullet bg-success me-3"></div>
                                <div className="text-gray-400">Activos</div>
                                <div className="ms-auto fw-bolder text-gray-700">{totalScortActive}</div>
                            </div>
                            {/* <div className="d-flex fs-6 fw-bold align-items-center mb-3">
                                <div className="bullet bg-danger me-3"></div>
                                <div className="text-gray-400">Overdue</div>
                                <div className="ms-auto fw-bolder text-gray-700">0</div>
                            </div> */}
                            <div className="d-flex fs-6 fw-bold align-items-center">
                                <div className="bullet bg-gray-300 me-3"></div>
                                <div className="text-gray-400">Inactivos</div>
                                <div className="ms-auto fw-bolder text-gray-700">{totalScortInactive}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}