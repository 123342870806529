import axios from 'axios'
import https from 'https';

const API_URL = process.env.REACT_APP_API_URL || 'api'

const agent = new https.Agent({  
  rejectUnauthorized: false
});

export const GET_FILTERS_URL = `${API_URL}/api/frontend/filters`

// Server should return AuthModel
export function getFilters() {
  const result = axios.get<any>(GET_FILTERS_URL, { httpsAgent: agent });
  return result;
}
