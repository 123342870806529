/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
  tenScortMoreVisit: Array<any>
}

const ListEscortVisited: React.FC<Props> = ({className, tenScortMoreVisit}) => {
  const listItems = tenScortMoreVisit.map((item: any) => (
    <div className='d-flex align-items-sm-center mb-7' key={item?.slug}>
    <div className='symbol symbol-60px symbol-2by3 me-4'>
      <div className="cursor-pointer symbol symbol-30px symbol-md-60px">
        <img src={item?.mainImage} alt={item?.alias} />
      </div>
    </div>
    <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
      <div className='flex-grow-1 my-lg-0 my-2 me-2'>
        <span className='text-gray-800 fw-bolder text-hover-primary fs-6'>
          {item?.alias}
        </span>
        <span className='text-muted fw-bold d-block pt-1'>{item?.age} Años</span>
      </div>
      <div className='d-flex align-items-center'>
        <div className='me-6'>
          <i className='fa fa-star-half-alt me-1 text-warning fs-5'></i>
          <span className='text-gray-800 fw-bolder'>{item?.register}</span>
        </div>
        {/* <a href='#' className='btn btn-icon btn-light btn-sm border-0'>
          <KTSVG
            path='/media/icons/duotone/Navigation/Arrow-right.svg'
            className='svg-icon-2 svg-icon-primary'
          />
        </a> */}
      </div>
    </div>
    </div>
  ));

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder text-dark'>Top 10 mascotas más visitadas</span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
       {listItems}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListEscortVisited}
