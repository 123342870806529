import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from 'app/themes/_sexisur/partials'
import {DashboardWrapper} from 'app/modules/dashboard/pages/DashboardWrapper'

export function PrivateRoutes() {
  
  // Users
  const AccountManagerPage = React.lazy(() => import('app/modules/accounts/manager/pages/AccountManagerPage'))
  const AccountClientPage = React.lazy(() => import('app/modules/accounts/client/pages/AccountClientPage'))
  const AccountEscortPage = React.lazy(() => import('app/modules/accounts/escort/pages/AccountEscortPage'))
  const EscortForm = React.lazy(() => import('app/modules/accounts/escort/components/form-register/EscortForm'))
  const PositionScortPage = React.lazy(() => import('app/modules/accounts/escort/pages/PositionScortPage'))
  
  // Complaint
  const ComplaintPage = React.lazy(() => import('app/modules/complaint/pages/ComplaintPage'))

  // Nomenclators
  const BodyTypesPage = React.lazy(() => import('app/modules/nomenclators/body-types/pages/BodyTypesPage'))
  const EyesPage = React.lazy(() => import('app/modules/nomenclators/eyes/pages/EyesPage'))
  const HeightsPage = React.lazy(() => import('app/modules/nomenclators/heights/pages/HeightsPage'))
  const EthnicsPage = React.lazy(() => import('app/modules/nomenclators/ethnics/pages/EthnicsPage'))
  const CitiesPage = React.lazy(() => import('app/modules/nomenclators/cities/pages/CitiesPage'))
  const ServicesPage = React.lazy(() => import('app/modules/nomenclators/services/pages/ServicesPage'))
  const ComplaintReasonsPage = React.lazy(() => import('app/modules/nomenclators/complaint-reasons/pages/ComplaintReasonsPage'))

  const ProfileManagerPage = React.lazy(() => import('app/modules/profile/manager/pages/ProfileManagerPage'))
  const ProfileEscortPage = React.lazy(() => import('app/modules/profile/escort/pages/ProfileEscortPage'))

  return (
    <React.Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />

        <Route path='/clients' component={AccountClientPage} />
        <Route path='/managers' component={AccountManagerPage} />
        <Route path='/escorts' component={AccountEscortPage} />
        <Route path='/register' component={EscortForm} />
        <Route path='/positions' component={PositionScortPage} />
        
        {/* <Route path='/escorts/update/general/:id' component={EscortForm} /> */}
        
        <Route path='/body-types' component={BodyTypesPage} />
        <Route path='/eyes' component={EyesPage} />
        <Route path='/heights' component={HeightsPage} />
        <Route path='/ethnics' component={EthnicsPage} />
        <Route path='/cities' component={CitiesPage} />
        <Route path='/services' component={ServicesPage} />
        <Route path='/complient-reasons' component={ComplaintReasonsPage} />
        
        
        <Route path='/profile/manager' component={ProfileManagerPage} />
        <Route path='/profile/escort/:id' component={ProfileEscortPage} />
        <Route path='/complaint' component={ComplaintPage} />
        

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </React.Suspense>
  )
}
