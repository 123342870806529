import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen text-center'>
      <img src={toAbsoluteUrl('/media/logos/logo-dark.svg')} alt='Start logo' width="200" height="200" />
      <br />
      <span>Cargando ...</span>
    </div>
  )
}
