import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from 'app/themes/_sexisur/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from 'app/themes/_sexisur/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import { Toaster } from 'react-hot-toast';
import FiltersInit from './modules/filters/redux/FiltersInit'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <FiltersInit>
                <Routes />
                <Toaster />
              </FiltersInit>
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
