import {IBodyTypesModel} from '../models/BodyTypesModel'

export class BodyTypesTableMock {
  public static table: Array<IBodyTypesModel> = [
    {
      "id": '1',
      "name": "Delgada"
    },
    {
      "id": '2',
      "name": "Gordita"
    },
    {
      "id": '4',
      "name": "Otros"
    },
    {
      "id": '3',
      "name": "Promedio"
    }
  ]
}
