import React from 'react'
import {connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from 'app/themes/_sexisur/layout/core'
import * as filters from './FiltersRedux';
import {getFilters} from '../services/FiltersService'
import {RootState} from 'app/setup'

const mapState = (state: RootState) => ({filters: state.filters})
const connector = connect(mapState, filters.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const FiltersInit: React.FC<PropsFromRedux> = (props) => {
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = React.useState(true)

  // We should request filters before rendering the application
  React.useEffect(() => {
    const requestFilters = async () => {
      try {
          const {data} = await getFilters();
          dispatch(props.fulfillFilter(data.data))
      } catch (error) {
        console.error(error)
      } finally {
        setShowSplashScreen(false)
      }
    }

    requestFilters();
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(FiltersInit)
