import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const DASHBOARD_URL = `${API_URL}/api/backend/dashboard`

export const all = async () => {
    /* eslint-disable no-unused-vars */
    const res = await axios.get<any>(`${DASHBOARD_URL}`)
    return res.data;
    
};
