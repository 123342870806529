import MockAdapter from 'axios-mock-adapter';
import {BodyTypesTableMock} from './bodyTypesTableMock';
import {IBodyTypesModel} from '../models/BodyTypesModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const BODY_TYPES_URL = `${API_URL}/backend/bodytypes`

export function mockBodyTypes(mock: MockAdapter) {
  mock.onGet(BODY_TYPES_URL).reply(() => {
    const bodyTypes = BodyTypesTableMock.table
    const bodyTypesSuccess = {
      success: true,
      data: bodyTypes,
      status: 200,
      total: BodyTypesTableMock.table.length
    }
    return [200, {...bodyTypesSuccess}]
  })

  mock.onPost(BODY_TYPES_URL).reply(({data}) => {
    const {name} = JSON.parse(data)

    if (name) {
      const bodyTypes: IBodyTypesModel = {
        id: generateBodyTypesId().toString(),
        name
      }
      
      BodyTypesTableMock.table.push(bodyTypes)
      const bodyTypesSuccess = {
        success: true,
        data: bodyTypes,
        status: 201
      }

      return [201, {...bodyTypesSuccess}]
    }

    return [400];
  })

  mock.onPut(/api\/backend\/bodytypes\/\d+/).reply(({data}) => {
    const {id, name} = JSON.parse(data)

    if (id && name) {
      const bodyTypes: IBodyTypesModel = {
        id,
        name
      }

      const newBodyTypesTableMock = BodyTypesTableMock.table.map((item: IBodyTypesModel) => item.id === bodyTypes.id ? {...item, ...bodyTypes} : item );
      BodyTypesTableMock.table = newBodyTypesTableMock
      const bodyTypesSuccess = {
        success: true,
        data: bodyTypes,
        status: 200
      }

      return [200, {...bodyTypesSuccess}]
    }

    return [400];
  })

  mock.onDelete(/api\/backend\/bodytypes\/\d+/).reply((config) => {
    const split_url = (config.url?.split('/'));
    const total = split_url?.length
    const index = total ? total - 1 : 0
    const id = split_url ? parseInt(split_url[index]) : 1
    const newBodyTypesTableMock = BodyTypesTableMock.table.filter((item: IBodyTypesModel) => item.id !== id.toString());
    BodyTypesTableMock.table = newBodyTypesTableMock

    return [204]
  })

  function generateBodyTypesId(): number {
    const ids = BodyTypesTableMock.table.map((el: IBodyTypesModel) => parseInt(el.id));
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}

