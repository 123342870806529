import React from 'react'
import clsx from 'clsx'
import {shallowEqual, useSelector} from 'react-redux'
import {HeaderUserMenu} from '../../../partials'
import {RootState} from 'app/setup'
import { toAbsoluteUrl } from 'app/themes/_sexisur/helpers'
import {UserModel} from 'app/modules/auth/models/UserModel'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: React.FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
     
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={user && user.mainImage ? user.mainImage : toAbsoluteUrl('/media/avatars/blank.jpg')} alt={user.alias} />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

    </div>
  )
}

export {Topbar}
