/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from 'app/themes/_sexisur/helpers'
import {Link} from 'react-router-dom';

type Props = {
  className: string
}

const ButtonActions: React.FC<Props> = ({className}) => {

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 py-14`}>
        <h3 className='card-title fw-bolder'>Acciones de mascotas</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          className={`mixed-widget-2-chart card-rounded-bottom`}
        ></div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-n20 position-relative'>
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col-3 bg-light-primary px-6 py-8 rounded-2 mb-7'>
              <Link to="/register">
                <KTSVG
                  path='/media/icons/duotone/Communication/Add-user.svg'
                  className='svg-icon-3x svg-icon-primary d-block my-2'
                />
                <span className='text-primary fw-bold fs-6'>
                  Crear nueva mascotas
                </span>
              </Link>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-3 bg-light-primary px-6 py-8 rounded-2 mb-7'>
              <Link to="/escorts">
                <KTSVG
                  path='/media/icons/duotone/General/User.svg'
                  className='svg-icon-3x svg-icon-primary d-block my-2'
                />
                <span className='text-primary fw-bold fs-6'>
                  Listar mascotas
                </span>
              </Link>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-3 bg-light-primary px-6 py-8 rounded-2 mb-7'>
              <Link to="/positions">
                <KTSVG
                  path='/media/icons/duotone/General/Thunder.svg'
                  className='svg-icon-3x svg-icon-primary d-block my-2'
                />
                <span className='text-primary fw-bold fs-6'>
                  Cambiar TOP mascotas
                </span>
              </Link>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
          
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ButtonActions}
