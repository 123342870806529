/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from 'app/themes/_sexisur/layout/core'
import {
  ButtonActions,
} from '../components/actions/ButtonActions'
import { ListEscortRegistered } from '../components/list/ListEscortRegistered'
import { ListClientRegistered } from '../components/list/ListClientRegistered'
import { all } from '../services/CRUDService'
import { DashboardModel } from '../models/DashboardModel'
import { ListEscortFavorites } from '../components/list/ListEscortFavorites'
import { ListEscortVisited } from '../components/list/ListEscortVisited'
import { ClientStats } from '../components/stats/ClientStats'
import { EscortStats } from '../components/stats/EscortStats'


const initialValues: DashboardModel = {
  lastTenScortRegister: [],
  lastTenClientRegister: [],
  tenScortMoreVisit: [],
  tenScortMoreFavorie: [],
  totalScortInactive: 0,
  totalScortActive: 0,
  totalClientActive: 0,
  totalClientInactive: 0
}

const DashboardPage: React.FC = () => {
  const [dashInfo, setDashInfo] = React.useState<DashboardModel>(initialValues);
  const {
    lastTenScortRegister,
    lastTenClientRegister,
    tenScortMoreVisit,
    tenScortMoreFavorie,
    totalScortInactive,
    totalScortActive,
    totalClientActive,
    totalClientInactive
  } = dashInfo;
  
  React.useEffect(() => {
    async function getDashInfo() {
      let {data} = await all();
      setDashInfo(data)
    }

    getDashInfo()
  }, []);
  
  return (
    <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-12'>
        <ButtonActions
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xxl-6'>
        <EscortStats totalScortActive={totalScortActive} totalScortInactive={totalScortInactive} />
      </div>
      <div className='col-xxl-6'>
        <ClientStats totalClientActive={totalClientActive} totalClientInactive={totalClientInactive} />
      </div>
      <div className='col-xxl-3'>
        <ListEscortRegistered className='card-xxl-stretch' lastTenScortRegister={lastTenScortRegister} />
      </div>
      <div className='col-xxl-3'>
        <ListClientRegistered className='card-xxl-stretch' lastTenClientRegister={lastTenClientRegister} />
      </div>
      <div className='col-xxl-3'>
        <ListEscortFavorites className='card-xxl-stretch' tenScortMoreFavorie={tenScortMoreFavorie} />
      </div>
      <div className='col-xxl-3'>
        <ListEscortVisited className='card-xxl-stretch' tenScortMoreVisit={tenScortMoreVisit} />
      </div>
      {/* <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div> */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div> */}
    {/* end::Row */}

    {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
  </>
  )
}

const DashboardWrapper: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
