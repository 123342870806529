/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Mascotas</span>
        </div>
      </div>
      <AsideMenuItem
        to='/escorts'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Mascotas Registrados"
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administradores</span>
        </div>
      </div>
      <AsideMenuItem
        to='/managers'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Administradores Registrados"
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Usuarios</span>
        </div>
      </div>
      <AsideMenuItem
        to='/clients'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Clientes Registrados"
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Denuncias</span>
        </div>
      </div>
      <AsideMenuItem
        to='/complaint'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Denuncias"
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Nomencladores</span>
        </div>
      </div>
      <AsideMenuItem
        to='/body-types'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Tipo de cuerpos"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/eyes'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Color de ojos"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/heights'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Altura"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/ethnics'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Color de piel"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/cities'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Ciudades"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/services'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Servicios"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/complient-reasons'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title="Motivos de denuncia"
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
